import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import auth from './auth';

export default new Vuex.Store({
    state: {
        uploads: `${process.env.VUE_APP_API}/uploads`,
    },
    mutations: {
    },
    actions: {
    },
    modules: {
        auth,
    }
});
