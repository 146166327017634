<template>
    <div>
        <p>Добро пожаловать в панель управления блогом!</p>
        <p>Для продолжения, выберите пункт меню слева.</p>
    </div>
</template>

<script>
export default {
    name: "Home",
};
</script>
