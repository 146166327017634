import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from '@p/vuetify';

import api from '@p/api';
Vue.use(api);

import dialog from '@p/dialog';
Vue.use(dialog, {
    vuetify,
});

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app');
