import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import ru from 'vuetify/es5/locale/ru';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: (localStorage.dark ?? 0) > 0,
    },
    lang: {
        locales: { ru },
        current: 'ru',
    }
});
