import store from '@/store';
import { api } from '@p/api';
/**
 * @type {import("vue-router").NavigationGuard}
 */
const guard = async (to, from, next) => {
    if (to.name != 'Login') {
        const { status } = await api("/test");
        if (!status) {
            store.commit('auth/logined', false);
            next({ name: 'Login' });
            return;
        } else {
            store.commit('auth/logined', true);
        }
    }
    next();
};
export default guard;
