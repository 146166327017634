<template>
    <v-app>
        <v-overlay :value="loading" z-index="1000">
            <v-progress-circular indeterminate size="64" />
        </v-overlay>
        <v-navigation-drawer
            app
            v-if="!isLogin"
            :temporary="!isHome"
            v-model="drawer"
            class="drawer"
            :permanent="isHome"
        >
            <v-list>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>Блог</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <v-divider />
            <v-list>
                <v-list-item :to="{ name: 'CatList' }">
                    <v-list-item-icon>
                        <v-icon>mdi-folder</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Категории</v-list-item-title>
                </v-list-item>
                <v-list-item :to="{ name: 'AuthorList' }">
                    <v-list-item-icon>
                        <v-icon>mdi-account-multiple</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Авторы</v-list-item-title>
                </v-list-item>
                <v-list-item :to="{ name: 'NewsList' }">
                    <v-list-item-icon>
                        <v-icon>mdi-newspaper</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Новости</v-list-item-title>
                </v-list-item>
            </v-list>
            <v-divider />
            <v-list>
                <v-list-item @click="logout">
                    <v-list-item-icon>
                        <v-icon>mdi-logout</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Выход</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>

        <v-app-bar app v-if="!isLogin">
            <v-app-bar-nav-icon @click.stop="drawer = !drawer" v-if="!isHome" />
            <v-toolbar-title>{{ $route.meta.title }}</v-toolbar-title>
            <v-spacer />
            <v-btn icon @click="dark">
                <v-icon>mdi-brightness-4</v-icon>
            </v-btn>
        </v-app-bar>

        <v-main>
            <v-container :fill-height="isLogin" fluid>
                <router-view />
            </v-container>
        </v-main>
    </v-app>
</template>

<style lang="scss" scoped>
.drawer {
    will-change: initial;
}
</style>

<script>
export default {
    name: "App",

    data: () => ({
        drawer: false,
        loading: false,
    }),
    computed: {
        isLogin() {
            return this.$route.name == "Login";
        },
        isHome() {
            return this.$route.name == "Home";
        },
    },
    methods: {
        dark() {
            this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
            localStorage.dark = this.$vuetify.theme.dark ? 1 : 0;
        },
        async logout() {
            this.loading = true;
            await this.$api("/logout");
            this.$store.commit("auth/logined", false);
            this.$router.push({ name: "Login" });
            this.drawer = false;
            this.loading = false;
        },
    },
};
</script>
