/**
 * 
 * @param {RequestInfo} url 
 * @param {?RequestInit} options 
 * @returns {Promise<any>}
 */
export async function api(url, options = {}) {
    if ('body' in options) {
        options.body = JSON.stringify(options.body);
    }
    const r = await fetch(`${process.env.VUE_APP_API}${url}`, {
        ...options,
        ...{
            headers: {
                'Content-type': 'application/json',
            }
        },
        credentials: 'include',
    });
    if (!r.ok) {
        const { error = 'Непредвиденная ошибка' } = await r.json();
        throw new Error(error);
    }
    return r.json();
}

export default {
    install(Vue) {
        Vue.prototype.$api = api;
        Vue.prototype.$admin = (url, options = {}) => api(`/admin${url}`, options);
    }
};