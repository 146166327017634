import VuetifyDialog from 'vuetify-dialog';
import 'vuetify-dialog/dist/vuetify-dialog.css';
export default {
    install(Vue, { vuetify }) {
        Vue.use(VuetifyDialog, {
            context: {
                vuetify,
            },
        });
        Vue.prototype.$error = function (message, options = {}) {
            return this.$dialog.notify.error(message, { position: "bottom-right", ...options });
        };
        Vue.prototype.$prompt = function (options = {}) {
            return this.$dialog.prompt({
                title: 'Внимание',
                text: 'Введите значение',
                actions: {
                    false: {
                        text: 'Отмена',
                        color: 'error',
                    },
                    true: {
                        text: 'Продолжить',
                        color: 'primary',
                    }
                },
                ...options,
            });
        };
        Vue.prototype.$confirm = function (options = {}) {
            return this.$dialog.confirm({
                title: 'Внимание',
                text: 'Подтвердите действие',
                actions: {
                    false: {
                        text: 'Отмена',
                        color: 'error',
                    },
                    true: {
                        text: 'Продолжить',
                        color: 'primary',
                    }
                },
                ...options,
            });
        };
    }
};
