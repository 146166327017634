<template>
    <v-card width="400" :loading="loading" class="mx-auto">
        <v-card-title>Вход</v-card-title>
        <v-form @submit.prevent="send" ref="form" :disabled="loading">
            <v-card-text>
                <v-text-field label="Логин" :rules="req" v-model="username" />
                <v-text-field
                    label="Пароль"
                    :rules="req"
                    v-model="password"
                    type="password"
                />
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn type="submit" text :disabled="loading" color="primary">
                    Продолжить
                </v-btn>
            </v-card-actions>
        </v-form>
    </v-card>
</template> 

<script>
export default {
    name: "Login",
    data: () => ({
        username: "",
        password: "",
        loading: false,
    }),
    mounted() {
        if (this.$store.state.auth.logined) {
            this.$router.push({ name: "Home" });
        }
    },
    methods: {
        async send() {
            const v = this.$refs.form.validate();
            if (!v) {
                return;
            }
            this.loading = true;
            try {
                await this.$api("/login", {
                    method: "POST",
                    body: {
                        username: this.username,
                        password: this.password,
                    },
                });
                this.$store.commit("auth/logined", true);
                this.$router.push({ name: "Home" });
            } catch (e) {
                this.$error(e.message);
            }
            this.loading = false;
        },
    },
    computed: {
        req: () => [(v) => !!v || "Заполните поле"],
    },
};
</script>
