import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@v/Home';
import Login from '@v/Login';

Vue.use(VueRouter);

/**
 * @type {import('vue-router').RouteConfig[]}
 */
const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            title: 'Управление',
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            title: 'Вход',
        }
    },
    {
        path: '/cat',
        component: {
            name: 'CatRoot',
            render: h => h('router-view'),
        },
        children: [
            {
                meta: {
                    title: 'Категории',
                },
                path: '',
                name: 'CatList',
                component: () => import('@v/Cat/List'),
            },
            {
                meta: {
                    title: 'Категория',
                },
                path: ':id',
                name: 'CatOne',
                props: r => ({
                    id: Number(r.params.id),
                }),
                component: () => import('@v/Cat/One'),
            },
        ],
    },
    {
        path: '/author',
        component: {
            name: 'AuthorRoot',
            render: h => h('router-view'),
        },
        children: [
            {
                path: '',
                name: 'AuthorList',
                meta: {
                    title: 'Авторы',
                },
                component: () => import('@v/Author/List'),
            },
            {
                meta: {
                    title: 'Автор',
                },
                path: ':id',
                name: 'AuthorOne',
                props: r => ({
                    id: Number(r.params.id),
                }),
                component: () => import('@v/Author/One'),
            },
        ],
    },
    {
        path: '/news',
        component: {
            name: 'NewsRoot',
            render: h => h('router-view'),
        },
        children: [
            {
                path: '',
                name: 'NewsList',
                meta: {
                    title: 'Новости',
                },
                component: () => import('@v/News/List'),
            },
            {
                path: ':id',
                name: 'NewsOne',
                meta: {
                    title: 'Новость',
                },
                props: r => ({
                    id: Number(r.params.id),
                }),
                component: () => import('@v/News/One'),
            },
        ],
    }
    // {
    //     path: '/about',
    //     name: 'About',
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    // }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

import seo from './seo';
router.beforeEach(seo);

import auth from './auth';
router.beforeEach(auth);

export default router;
